var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { position: "relative" } },
    [
      _c(
        "el-form",
        {
          ref: "editForm",
          staticClass: "edit-form",
          attrs: {
            model: _vm.editForm,
            "label-position": "right",
            rules: _vm.rules,
            "label-width": "110px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "活动名称：", prop: "name" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入名称", clearable: "" },
                model: {
                  value: _vm.editForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "name", $$v)
                  },
                  expression: "editForm.name"
                }
              }),
              _c("span", [_vm._v("建议（x月+小区名+活动）作为名称")])
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "活动人员：", prop: "manager_id" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: "输入充值管理员关键词搜索",
                    "remote-method": _vm.remoteVillage,
                    loading: _vm.memberLoading,
                    clearable: ""
                  },
                  model: {
                    value: _vm.editForm.manager_id,
                    callback: function($$v) {
                      _vm.$set(_vm.editForm, "manager_id", $$v)
                    },
                    expression: "editForm.manager_id"
                  }
                },
                _vm._l(_vm.memberOps, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: { label: item.name || item.mobile, value: item.id }
                    },
                    [
                      _c("span", { staticClass: "text-left" }, [
                        _vm._v(_vm._s(item.name || item.mobile))
                      ]),
                      _c("span", { staticClass: "remote-select_right" }, [
                        _vm._v(_vm._s(item.mobile))
                      ])
                    ]
                  )
                }),
                1
              ),
              _c("span", { staticStyle: { color: "red" } }, [
                _vm._v("（已参加进行中或未开始的人员无法添加）")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "优惠选择：", prop: "coupon_id" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    clearable: "",
                    placeholder: "请选择优惠项"
                  },
                  model: {
                    value: _vm.editForm.coupon_id,
                    callback: function($$v) {
                      _vm.$set(_vm.editForm, "coupon_id", $$v)
                    },
                    expression: "editForm.coupon_id"
                  }
                },
                _vm._l(_vm.discountOps, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    staticClass: "date-picker_width",
                    attrs: {
                      label: "充" + item.money + "送" + item.gives,
                      value: item.id
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "block-item block-time",
              attrs: { label: "活动时间：", prop: "time" }
            },
            [
              _c("el-date-picker", {
                staticClass: "date-picker_width",
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetimerange",
                  "range-separator": "-",
                  "picker-options": _vm.pickerOptions,
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "unlink-panels": true,
                  align: "right"
                },
                on: { change: _vm.handleTime },
                model: {
                  value: _vm.editForm.time,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "time", $$v)
                  },
                  expression: "editForm.time"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "footer-container",
          staticStyle: { "margin-top": "30px" }
        },
        [
          _vm.editStatus === "add"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: false, size: "medium" },
                  on: { click: _vm.handleAdd }
                },
                [_vm._v("添加")]
              )
            : _vm._e(),
          _vm.editStatus === "update"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: false, size: "medium" },
                  on: { click: _vm.handleUpdate }
                },
                [_vm._v("修改")]
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { size: "medium" }, on: { click: _vm.goBack } },
            [_c("span", [_vm._v("取消")])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }