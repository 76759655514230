import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchRefillList, fetchEnoughList, addAc, updateAc, fetchAcDetail } from "@/api/member";
import "@/utils/global.js";
import { portList } from "@/utils/util";
import "@/utils/global.js";
import { prototype } from "events";
export default {
  name: "edit",
  data: function data() {
    var self = this;
    return {
      // 查询字段
      editForm: {
        name: "",
        manager_id: "",
        coupon_id: "",
        start_at: "",
        end_at: "",
        time: "",
        id: ""
      },
      rules: {
        name: [{
          required: true,
          message: "活动名称必填",
          trigger: "blur"
        }],
        manager_id: [{
          required: true,
          message: "活动人员必选",
          trigger: "blur"
        }],
        coupon_id: [{
          required: true,
          message: "活动优惠必选",
          trigger: "blur"
        }],
        time: [{
          required: true,
          message: "活动时间必选",
          trigger: "blur"
        }]
      },
      //
      editStatus: "add",
      discountOps: [],
      memberOps: [],
      memberLoading: false,
      pickerOptions: _global.timeOption2,
      villageLoading: false,
      villageOptions: []
    };
  },
  created: function created() {
    this.getEnoughList();
    this.init();
  },
  methods: {
    getEnoughList: function getEnoughList() {
      var _this = this;

      fetchEnoughList().then(function (res) {
        console.log(res);
        _this.discountOps = res.data;
      });
    },
    getAcDetail: function getAcDetail(id) {
      var _this2 = this;

      fetchAcDetail(id).then(function (res) {
        console.log(res, "edit detail");
        var data = res.data;
        var memberid = ""; // this.discountOps = res.data;

        _this2.memberOps.push(data.manager); // this.editForm["manager_id"] = data.manager;


        _this2.editForm["name"] = data.name;
        _this2.editForm["manager_id"] = data.manager.id;
        _this2.editForm["coupon_id"] = _this2.transCoupons(data.coupons);
        _this2.editForm["start_at"] = data.start_at;
        _this2.editForm["end_at"] = data.end_at;
        _this2.editForm["time"] = [data.start_at, data.end_at];
        _this2.editForm["id"] = data.id;
      });
    },
    init: function init() {
      if (this.$route.query && this.$route.query.id) {
        this.editStatus = "update";
        this.getAcDetail(this.$route.query.id);
      }
    },
    handleAdd: function handleAdd() {
      var _this3 = this;

      this.$refs["editForm"].validate(function (valid) {
        if (valid) {
          console.log(_this3.editForm);
          var obj = Object.assign({}, _this3.editForm); // obj["manager_id"] = this.transArray(this.editForm["manager_id"]);

          obj["coupon_id"] = _this3.transArray(_this3.editForm["coupon_id"]);
          addAc(obj).then(function (response) {
            _this3.$message({
              type: "success",
              message: "优惠活动添加成功"
            });

            console.log("跳转");

            _this3.goBack();
          }).catch(function (err) {
            console.log(err);

            _this3.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate() {
      var _this4 = this;

      this.$refs["editForm"].validate(function (valid) {
        if (valid) {
          updateAc(_this4.editForm).then(function (response) {
            _this4.$message({
              type: "success",
              message: "修改成功"
            });

            console.log("跳转");

            _this4.goBack();
          }).catch(function (err) {
            console.log(err);

            _this4.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    },
    remoteVillage: function remoteVillage(query) {
      var _this5 = this;

      console.log("remote", query);

      if (query !== "") {
        this.memberLoading = true;
        setTimeout(function () {
          var data = {
            keyword: query
          };
          fetchRefillList(data).then(function (res) {
            console.log(res);
            _this5.memberLoading = false;
            _this5.memberOps = res.data;
          }).catch(function (err) {
            console.log(err);
            _this5.memberLoading = false;
          });
        }, 100);
      } else {
        this.memberOps = [];
      }
    },
    handleTime: function handleTime(val) {
      if (val) {
        this.editForm["start_at"] = val[0];
        this.editForm["end_at"] = val[1];
      } else {
        this.editForm["start_at"] = "";
        this.editForm["end_at"] = "";
      }
    },
    goBack: function goBack() {
      console.log(this.searchForm);
      this.$router.back(-1);
    },
    transArray: function transArray(data) {
      if (data) {
        return data.join(",");
      }
    },
    transCoupons: function transCoupons(arr) {
      if (arr) {
        console.log(arr);
        var newArr = [];

        for (var i in arr) {
          newArr.push(parseInt(arr[i].id));
        }

        return newArr;
      }
    }
  }
};